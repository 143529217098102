<script>
import axios from 'axios'
import './feature-feedback.scss'

export default {
  name: 'feature-feedback',
  props: {
    featureName: {
      type: String,
      default: ''
    },
    featureData: {
      type: Object,
      default (rawProps) {
        return {}
      }
    }
  },
  data () {
    return {
      feedback: '',
      positiveRating: null,
      feedbackSent: false
    }
  },
  methods: {
    assignRating (rating) {
      this.positiveRating = rating
    },
    recordMixpanelEvent () {
      const event = { name: `${this.featureName}_feedback`, positive_rating: this.positiveRating, feedback: this.feedback }
      const eventData = Object.assign({}, event, this.featureData)
      axios.post('/mixpanel/events', { event_related_data: eventData })
    },
    submitFeedback () {
      this.recordMixpanelEvent()
      this.feedbackSent = true
      toastr.success('Your feedback have been sent')
    }
  }
}
</script>

<template>
  <div class="dw-feature-feedback">
    <div class="dw-feature-feedback-title">
      Rate your experiencie
      <div class="dw-feature-feedback-title-icons">
        <i
          class="far fa-thumbs-up"
          :class="{ fas: positiveRating }"
          @click="assignRating(true)"
        />
        <i
          class="far fa-thumbs-down"
          :class="{ fas: (positiveRating !== null && !positiveRating) }"
          @click="assignRating(false)"
        />
      </div>
    </div>

    <form
      v-if="positiveRating !== null && !feedbackSent"
      @submit.prevent="submitFeedback"
    >
      <div
        class="form-group"
      >
        <label for="feedback">How can this feature be improved?</label>
        <textarea
          id="feedback"
          v-model="feedback"
          class="form-control"
          placeholder="Please share your feedback (optional)"
          rows="3"
          maxlength="255"
        />
      </div>

      <button
        class="btn btn-green full-width"
      >
        Submit Feedback
      </button>
    </form>
  </div>
</template>
